:root {
  --primary-900: #4952d0;
  --primary-700: #555fff;
  --primary-500: #9caefc;
  --primary-300: #dce6ff;
  --primary-100: #f0f4ff;
  --neutral-1000: #00081c;
  --neutral-900: #273143;
  --neutral-700: #505a6e;
  --neutral-500: #9aa9c3;
  --neutral-300: #d2dbe8;
  --neutral-100: #f9fafc;
  --neutral-0: #fff;
  --error-900: #f91974;
  --error-100: #fee8f1;
  --warning-900: #ff760d;
  --warning-100: #fff1e6;
  --success-900: #00bf56;
  --success-100: #e5f8ee;
  --title-font: 'Poppins';
  --regular-font: 'Roboto';
  --small: 12px;
  --regular: 14px;
  --medium: 20px;
  --large: 24px;
  --xlarge: 32px;
  --regular-lineheight: 100%;
  --paragraph-lineheight: 140%;
}
