  #okta-sign-in {
    width: inherit !important;
    margin: unset;
  }
  
  #okta-sign-in.auth-container.main-container {
    font-family: "PxGrotesk", sans-serif !important;
    font-weight: 200 !important;
    border: none;
  }
  
  #okta-sign-in .auth-content {
    padding: 0 !important;
  }
  
  #okta-sign-in.auth-container .okta-sign-in-header {
    border: none;
  }
  
  #okta-sign-in.no-beacon .auth-header {
    padding: 0 !important;
  }

  #okta-sign-in .okta-sign-in-header .beacon-container .okta-sign-in-beacon-border {
    border: 1px solid #555fff !important
  }

  #okta-sign-in .okta-sign-in-header .beacon-loading div::after {
    display: none !important;
  }

  #okta-sign-in.auth-container .okta-form-label {
    color: #505a6e !important;
    padding-left: 8px;
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  
  #okta-sign-in .o-form .input-fix input[type=text] {
    color: #3c2869 !important;
  }

  #okta-sign-in.auth-container .okta-form-input-field input[type=password] {
    box-shadow: none !important;
  }

  #okta-sign-in .o-form .input-fix input[type=text]:hover {
    border: 1px solid #555fff;
  }

  #okta-sign-in .o-form .input-fix input[type=text]:focus {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }

  #okta-sign-in.auth-container .okta-form-input-field input[type=password]:hover {
    border: 1px solid #555fff;
  }

  #okta-sign-in.auth-container .okta-form-input-field input[type=password] {
    color: #3c2869 !important;
    font-family: "PxGrotesk", "Proxima Nova", Arial, sans-serif !important;
  }
  
  #okta-sign-in.auth-container .okta-form-input-field {
    border-color: #ccc !important;
  }
  
  #okta-sign-in.auth-container .okta-form-input-field.focused-input {
    border-color: #ccc !important;
  }

  /* Button */
  #okta-sign-in.auth-container .button-primary {
    border: none !important;
    border-radius: 100px !important;
    font-family: "PxGrotesk", "Proxima Nova", Arial, sans-serif !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 40px !important;
    box-shadow: none !important;
    background: #555fff !important;
    margin-top:20px
  }

  #okta-sign-in.auth-container .auth-content-inner .primary-auth-container .btn-custom-login-sso {
    border: none !important;
    border-radius: 100px !important;
    font-family: "PxGrotesk", "Proxima Nova", Arial, sans-serif !important;
    font-weight: 500 !important;
    line-height: 40px;
    font-size: 14px;
    height: 40px !important;
    box-shadow: none !important;
    background: #555fff !important;
    margin-top:20px
  }
  
  #okta-sign-in.auth-container.main-container .margin-btm-30 {
    margin-bottom: 0 !important;
  }
  

  #okta-sign-in .auth-footer a[data-se="signout-link"] {
    font-size: 14px !important;
    color: #555fff !important;
  }
  
  .text-xs {
    font-size: .875rem !important;
  }
  
  #okta-sign-in #help-links-container .js-forgot-password {
    color: #555fff;
    font-size: 14px;
    font-weight: bolder;
    display: block !important;
  }
  
  /* Email MFA */
  #okta-sign-in.auth-container.main-container {
    color: #fff !important;
    margin: unset;
    overflow: hidden;
  }
  
  #okta-sign-in .auth-footer .goto {
    float: none !important;
  }

  #okta-sign-in .auth-content .primary-auth p[id^='input-container-error'] {
    background-color: #fee8f1;
    margin-top: 50px;
    border: 1px solid #f91974;
    border-radius: 0.25rem;
    color: black;
  }

  #okta-sign-in .auth-content .primary-auth .o-form-has-errors .okta-form-infobox-error {
    color: #6e6e78;
    background-color: #FFF1E6;
    border: 1px solid #FF760D;
  }

  #okta-sign-in .auth-content .mfa-verify .o-form-has-errors .okta-form-infobox-error {
    color: #6e6e78;
    background-color: #FFF1E6;
    border: 1px solid #FF760D;
  }

  #okta-sign-in .auth-content .mfa-verify {
    margin-top: 60px
  }

  #okta-sign-in .auth-content .mfa-verify .infobox-error p {
    color: #6e6e78;
  }

  #okta-sign-in .auth-content .mfa-verify .o-form-button-bar .button {
    display: none;
  }

  #okta-sign-in .auth-content .mfa-verify .o-form-input-name-rememberDevice .custom-checkbox label {
    color: #8492a6;
  }

  #okta-sign-in .auth-content .mfa-verify .o-form-button-bar .button[value="Verify"] {
    display: block;
  }

  #okta-sign-in .auth-content .mfa-verify .okta-form-title {
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
  }

  #okta-sign-in .infobox-error:before {
    background-color: #ff760d !important;
  }

  #okta-sign-in .infobox-warning:before {
    background-color: #ff760d !important;
  }

  #okta-sign-in .o-form-input-error .icon-16 {
    left: 1px !important;
  }

  #okta-sign-in .auth-content .resend-email-infobox .infobox-warning {
    border-radius: 4px;
    border: 1px solid #FF760D;
    background: #FFF1E6
  }

  #okta-sign-in .focused-input, #okta-sign-in .link.help:focus, #okta-sign-in input[type="radio"]:focus + label, #okta-sign-in input[type="text"]:focus {
    box-shadow: none !important;
  }

  #okta-sign-in .auth-content .resend-email-infobox .infobox-warning p span {
    color: black;
    font-size: 14px;
    font-weight: 400;
    font-family: Roboto;
    font-style: normal;
  }

  #okta-sign-in .auth-content .resend-email-infobox .infobox-warning .resend-email-btn {
    font-size: 14px;
    color: #555fff;
    font-family: Roboto;
    font-style: normal;
  }

  #okta-sign-in > div.auth-content > div > div > div.primary-auth-container > a.social-auth-button.social-auth-general-idp-button.link-button {
    background-size: contain;
    background-repeat: no-repeat;
  }

  
  #okta-sign-in .auth-divider .auth-divider-text {
    color: #505a6e;
  }